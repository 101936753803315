import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AxiosClient } from "redux/AxiosClient";

export const uploadVocabularyExcelAsync = createAsyncThunk(
  "admin/uploadVocabularyExcel",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadVocabularyExcel`,
      payload,
      toolkit
    );
  }
);

export const addVocWordAsync = createAsyncThunk(
  "admin/addVocWord",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addVocWord`, payload, toolkit);
  }
);

export const getAllVocWordAsync = createAsyncThunk(
  "admin/getAllVocWord",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllVocWord?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&classes=${payload.classes || ""}`,
      [],
      toolkit
    );
  }
);

export const getVocWordByIdAsync = createAsyncThunk(
  "admin/getVocWordById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getVocWordById/${payload}`, [], toolkit);
  }
);

export const bulkquestionInAsync = createAsyncThunk(
  "admin/bulkquestionInAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/uploadBulkQuestions`, payload, toolkit);
  }
);
export const bulkuploadquestionAsync = createAsyncThunk(
  "admin/uploadBulkQuestion",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadBulkQuestion`,
      payload,
      toolkit,
      "multipart/form-data"
    );
  }
);

export const updateVocWordByIdAsync = createAsyncThunk(
  "admin/updateVocWordById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateVocWordById`, payload, toolkit);
  }
);

export const updateWordOfTheDayByIdAsync = createAsyncThunk(
  "admin/updateWordOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateWordOfTheDayById`,
      payload,
      toolkit
    );
  }
);

export const createQuestionBankAsync = createAsyncThunk(
  "admin/createQuestionBank",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createQuestionBank`, payload, toolkit);
  }
);
export const getQuestionByIdAsync = createAsyncThunk(
  "admin/getQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getQuestionById/${payload}`, [], toolkit);
  }
);
export const updateQuestionAsync = createAsyncThunk(
  "admin/updateQuestionBankId",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateQuestionBankId`, payload, toolkit);
  }
);

export const deleteVocWordByIdAsync = createAsyncThunk(
  "admin/deleteVocWordById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteVocWordById/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteQuestionAsync = createAsyncThunk(
  "admin/deleteQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteQuestionById/${payload}`,
      [],
      toolkit
    );
  }
);
export const getChapterByMultipleSubjectIdAsync = createAsyncThunk(
  "admin/getChapterByMultipleSubjectId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getChapterByMultipleSubjectId`,
      payload,
      toolkit
    );
  }
);

export const getAllBulkFilesAsync = createAsyncThunk(
  "admin/getAllBulkFiles",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllBulkFiles?page=${payload.page}&limit=${payload.limit}&fileType=${payload.fileType}`,
      [],
      toolkit
    );
  }
);

// get question for scholarship test
export const getQuestionScholarshipTestAsync = createAsyncThunk(
  "admin/getQuestionScholarshipTestAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getScholarshipQuestions`,
      payload,
      toolkit
    );
  }
);
