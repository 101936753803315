import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  uploadIdiomsExcelAsync,
  getAllIdiomsAsync,
  createIdiomsAsync,
  getIdiomsByIdAsync,
  updateIdiomsByIdAsync,
  deleteIdiomsByIdAsync,
} from "./idioms.async";

const initialState = {
  idiomsLoader: false,
  getAllIdioms: [],
  idiomBulkLoader: false,
  bulkIdiom: [],
  addIdioms: [],
  updateIdiomById: [],
  getIdiomById: [],
  deleteLoader: false,
  deletedData: [],
};

export const idiomsSlice = createSlice({
  name: "Vocabulary",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        createIdiomsAsync.pending,
        getIdiomsByIdAsync.pending,
        updateIdiomsByIdAsync.pending
      ),
      (state) => {
        state.idiomsLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(uploadIdiomsExcelAsync.pending), (state) => {
      state.idiomBulkLoader = true;
    });

    builder.addMatcher(isAnyOf(deleteIdiomsByIdAsync.pending), (state) => {
      state.deleteLoader = true;
    });

    builder.addMatcher(
      isAnyOf(uploadIdiomsExcelAsync.fulfilled),
      (state, action) => {
        state.idiomBulkLoader = false;
        state.bulkIdiom = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadIdiomsExcelAsync.rejected),
      (state, action) => {
        state.idiomBulkLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(getAllIdiomsAsync.pending), (state) => {
      state.idiomsLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllIdiomsAsync.fulfilled),
      (state, action) => {
        state.idiomsLoader = false;
        state.getAllIdioms = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(createIdiomsAsync.fulfilled),
      (state, action) => {
        state.idiomsLoader = false;
        state.addIdioms = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getIdiomsByIdAsync.fulfilled),
      (state, action) => {
        state.idiomsLoader = false;
        state.getIdiomById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateIdiomsByIdAsync.fulfilled),
      (state, action) => {
        state.idiomsLoader = false;
        state.updateIdiomById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteIdiomsByIdAsync.fulfilled),
      (state, action) => {
        state.deleteLoader = false;
        state.deletedData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteIdiomsByIdAsync.rejected),
      (state, action) => {
        state.deleteLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(getAllIdiomsAsync.rejected), (state, action) => {
      state.idiomsLoader = false;
    });

    builder.addMatcher(
      isAnyOf(
        createIdiomsAsync.rejected,
        getIdiomsByIdAsync.rejected,
        updateIdiomsByIdAsync.rejected
      ),
      (state, action) => {
        state.idiomsLoader = false;
      }
    );
  },
  reducers: {
    emptyIdioms: (state) => initialState,
  },
});

export const { emptyIdioms } = idiomsSlice.actions;

export default idiomsSlice.reducer;
