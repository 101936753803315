import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const addGrammarContentAsync = createAsyncThunk(
  "admin/addGrammarContent",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addGrammarContent`, payload, toolkit);
  }
);

export const getAllgrammarContentAsync = createAsyncThunk(
  "admin/getAllGrammarContent",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllGrammarContent?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&classes=${payload.classes || ""}`,
      [],
      toolkit
    );
  }
);

export const grammarContentByIdAsync = createAsyncThunk(
  "admin/grammarContentById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/grammarContentById/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateGrammarContentAsync = createAsyncThunk(
  "admin/updateGrammarContent",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateGrammarContent`, payload, toolkit);
  }
);

export const deleteGrammarContentAsync = createAsyncThunk(
  "admin/deleteGrammarContent",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteGrammarContent/${payload}`,
      [],
      toolkit
    );
  }
);
