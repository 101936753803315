import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AxiosClient } from "redux/AxiosClient";

export const createIdiomsAsync = createAsyncThunk(
  "admin/createIdioms",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createIdioms`, payload, toolkit);
  }
);

export const getAllIdiomsAsync = createAsyncThunk(
  "admin/getAllIdioms",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllIdioms?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&classes=${payload.classes || ""}`,
      [],
      toolkit
    );
  }
);

export const getIdiomsByIdAsync = createAsyncThunk(
  "admin/getIdiomsById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getIdiomsById/${payload}`, [], toolkit);
  }
);

export const updateIdiomsByIdAsync = createAsyncThunk(
  "admin/updateIdiomsById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateIdiomsById`, payload, toolkit);
  }
);

export const updateWordOfTheDayByIdAsync = createAsyncThunk(
  "admin/updateWordOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateWordOfTheDayById`,
      payload,
      toolkit
    );
  }
);

export const createQuestionBankAsync = createAsyncThunk(
  "admin/createQuestionBank",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createQuestionBank`, payload, toolkit);
  }
);
export const getQuestionByIdAsync = createAsyncThunk(
  "admin/getQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getQuestionById/${payload}`, [], toolkit);
  }
);
export const updateQuestionAsync = createAsyncThunk(
  "admin/updateQuestionBankId",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateQuestionBankId`, payload, toolkit);
  }
);

export const deleteIdiomsByIdAsync = createAsyncThunk(
  "admin/deleteIdiomsById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteIdiomsById/${payload}`,
      [],
      toolkit
    );
  }
);

export const uploadIdiomsExcelAsync = createAsyncThunk(
  "admin/uploadIdiomsExcel",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadIdiomsExcel`,
      payload,
      toolkit
    );
  }
);

export const getAllBulkFilesAsync = createAsyncThunk(
  "admin/getAllBulkFiles",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllBulkFiles?page=${payload.page}&limit=${payload.limit}&fileType=${payload.fileType}`,
      [],
      toolkit
    );
  }
);
